<template>
  <v-card class="template-card" :disabled="loading" :loading="loading" fluid>
    <Alert/>
    <DialogCommunication
      @update-table='refresh'
    />
    <DetailRecipient/>
    <v-row align='center' align-content='center' justify='center'>
      <TableCommunication
        :update='updateTables'
      />
      <TableCommunicationTemplate
        v-if='user.rol === "Business"'
        :update='updateTables'
      />
    </v-row>
  </v-card>
</template>

<script>
import Alert from '../../components/Alert'
import TableCommunication from './components/TableCommunication'
import TableCommunicationTemplate from './components/TableCommunicationTemplate'
import DialogCommunication from './components/DialogCommunication'
import DetailRecipient from './components/DetailRecipient.vue'

export default {
  components: {
    Alert,
    TableCommunication,
    TableCommunicationTemplate,
    DialogCommunication,
    DetailRecipient
  },
  data() {
    return {
      updateTables: false,
      loading: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    }
  },
  methods: {
    async refresh() {
      this.loading = true
      await this.$store.dispatch('crud', {
        dialog: false,
        title: '',
        id: null,
        data: {}
      })
      await this.$router.go(0)
    }
  },
  async created() {
    await this.$store.dispatch('crud', {
      dialog: false,
      title: '',
      id: null,
      data: {}
    })
  },
  async updated() {
    await this.$store.dispatch('crud', {
      dialog: false,
      title: '',
      id: null,
      data: {}
    })
  }
}
</script>

<style scoped>
.template-card {
  box-shadow: none !important;
}
</style>
