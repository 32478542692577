import {HTTP} from '../../../http-common'
import helper from '../index'

export default {
  getMembers: async() => {
    try {
      const response = await HTTP.get('getEntity/2', {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  getTemplates: async() => {
    try {
      const response = await HTTP.get('communication-template-select', {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  getEmployees: async(data) => {
    try {
      const response = await HTTP.get('employees/get/select', {
        params: data,
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  getEmployeeRoles: async() => {
    try {
      const response = await HTTP.get('employees/get/roles', {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  index: async(data) => {
    try {
      const response = await HTTP.get('communication-template', {
        params: data,
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  indexCommunication: async(data) => {
    data.sortDesc[0] = true
    try {
      const response = await HTTP.get('communication', {
        params: data,
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  showCommunication: async(id) => {
    try {
      const response = await HTTP.get('communication/' + id, {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  show: async(id) => {
    try {
      const response = await HTTP.get('communication-template/' + id, {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  store: async(data) => {
    try {
      const response = await HTTP.post('communication', data, {
        headers: helper.setHeaders()
      })
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  updated: async(id, form) => {
    try {
      const response = await HTTP.put(`communication/${id}`, form, {
        headers: helper.setHeaders()
      })
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  storeTemplate: async(form) => {
    try {
      const response = await HTTP.post('communication-template', form, {
        headers: helper.setHeaders()
      })
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  updateTemplate: async(id, form) => {
    try {
      const response = await HTTP.put(`communication-template/${id}`, form, {
        headers: helper.setHeaders()
      })
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  deletedItemsTemplate: async(data) => {
    try {
      const response = await HTTP.post(
        `communication-template-deleteItems`,
        {
          data: data
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  deleted: async(id) => {
    try {
      const response = await HTTP.delete(`communication/${id}`, {
        headers: helper.setHeaders()
      })
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  storeReportingMap: async(data) => {
    try {
      const response = await HTTP.post('communication-reporting-map', data, {
        headers: helper.setHeaders()
      })
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  updateReportingMap: async(id, data) => {
    try {
      const response = await HTTP.post(
        'communication-reporting-map-update/' + id,
        data,
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  recipient: async(id) => {
    try {
      const response = await HTTP.get('communication-recipient/' + id, {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  },
  recipientTemplate: async(id) => {
    try {
      const response = await HTTP.get(
        'communication-template-recipient/' + id,
        {
          headers: helper.setHeaders()
        }
      )
      return response.data.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  }
}
