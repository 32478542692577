<template>
  <div v-frag>
    <v-container fluid>
      <v-col cols='12'>
        <v-card class='rounded-lg' elevation='3'>
          <v-card-title>
            <v-row>
              <v-col class='font-weight-bold' cols='12' md='5'>
                <span>{{ sections.communications || 'Communications title' }}</span>
              </v-col>
              <v-col cols='12' md='5'>
                <v-text-field
                  v-model='search'
                  class='rounded-lg'
                  dense
                  filled
                  hide-details
                  placeholder='Search'
                  prepend-inner-icon='mdi-magnify'
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='2'>
                <v-btn
                  block
                  class='white--text text-capitalize'
                  color='primary'
                  height='100%'
                  outlined
                  @click='openDialog(null)'
                >
                  <v-icon>mdi-plus</v-icon>
                  Add New
                </v-btn>
              </v-col>
              <v-col cols='12'>
                <v-tabs v-model='tab'>
                  <v-tab>Scheduled</v-tab>
                  <v-tab>Sent</v-tab>
                </v-tabs>
              </v-col>
              <div v-if='selected.length > 0' v-frag>
                <v-col cols='12' md='10'>
                  <div v-frag>
                    <v-btn
                      active-class='red-light'
                      class='red--text text-capitalize'
                      color='red'
                      outlined
                      @click='openDialogDelete(null)'
                    >
                      <v-icon color='red'>mdi-close-thick</v-icon>
                      <b class='red--text text-capitalize'>Delete</b>
                    </v-btn>
                  </div>
                </v-col>
              </div>
            </v-row>
          </v-card-title>
          <v-data-table
            v-model='selected'
            :headers='headers'
            :items='desserts'
            :items-per-page='10'
            :loading='loading'
            :options.sync='options'
            :server-items-length='totalDesserts'
            :single-select='false'
            checkboxColor='primary'
            elevation='0'
            item-key='id'
          >
            <template #item.recipient={item}>
              <v-btn
                color='primary'
                elevation='0'
                small
                @click='detailRecipient(item.id)'
              >
                Detail
              </v-btn>
            </template>

            <template #item.actions={item}>
              <div v-if='tab === 0'>
                <v-btn
                  class='mr-2 my-1 pa-2 primary white--text rounded-sm'
                  dense
                  min-height='35'
                  min-width='35'
                  @click='openDialog(item.id)'
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  class='mr-2 my-1 pa-2 red white--text rounded-sm'
                  dense
                  min-height='35'
                  min-width='35'
                  @click='openDialogDelete(item)'
                >
                  <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </div>
              <v-btn
                v-else
                class='mr-2 my-1 pa-2 primary white--text rounded-sm'
                dense
                min-height='35'
                min-width='35'
                @click='openDialog(item.id, true)'
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-container>

    <v-dialog
      v-model='dialogDelete'
      max-width='500px'
      persistent
    >
      <v-card class='d-flex flex-column align-start pa-2'>
        <v-card-title class='text-center mb-5'>
          <v-icon class='mr-4 white--text' color='warning' large left>mdi-alert</v-icon>
          Delete communication
        </v-card-title>
        <v-card-subtitle>
          <b>You are about to delete the following communication:</b>
        </v-card-subtitle>
        <!-- <v-list> -->
        <v-col cols='12'>
          <v-simple-table>
            <template #default>
              <thead>
              <tr>
                <th class='text-left'>Topic</th>
                <th class='text-left'>Recipient</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for='(item, index) in itemsDelete'
                :key='index'
              >
                <td>{{ item.topic }}</td>
                <td>{{ item.recipient }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold text-capitalize'
            color='primary'
            large
            outlined
            @click='closeDialogDelete'
          >
            Cancel
          </v-btn>
          <v-btn
            class='px-14 mx-4 font-weight-bold text-capitalize white--text'
            color='warning'
            large
            @click='deleteItems'
          >
            Delete
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import communication from '../../../services/communication'

export default {
  props: {
    update: {
      type: Boolean
    }
  },
  data() {
    return {
      itemsDelete: [],
      id: null,
      dialog: true,
      totalDesserts: 0,
      loading: false,
      selected: [],
      options: {},
      search: '',
      headers: [
        { text: 'Topic', align: 'start', value: 'topic' },
        { text: 'Message', align: 'start', value: 'text_mail' },
        { text: 'Scheduled Date', align: 'start', value: 'date' },
        { text: 'Recipient', align: 'start', sortable: false, value: 'recipient' },
        { text: 'type', align: 'start', value: 'type_id' },
        { text: 'From module', align: 'start', sortable: false, value: 'module' },
        { text: 'Actions', align: 'start', value: 'actions' }
      ],
      desserts: [],
      dialogDelete: false,
      tab: 0,
      setTimeoutTimer: null
    }
  },
  computed: {
    ...mapState({
      sections: state => state.cmsStore.sections
    })
  },
  watch: {
    async options() {
      await this.initialLoad()
    },
    search(newVal) {
      clearTimeout(this.setTimeoutTimer)
      this.setTimeoutTimer = setTimeout(async () => {
        this.options.page = 1
        this.options.search = newVal
        await this.initialLoad()
      }, 500)
    },
    async tab() {
      this.options.page = 1
      await this.initialLoad()
    },
    async update() {
      await this.initialLoad()
    }
  },
  methods: {
    async initialLoad() {
      this.loading = true
      try {
        this.desserts = []
        const { totalDesserts, desserts } = await communication.indexCommunication({ ...this.options, type: this.tab })
        this.totalDesserts = totalDesserts
        this.desserts = desserts
      } catch (e) {
        this.loading = false
      }
      this.loading = false
    },
    openDialog(id = null, disabled = false) {
      let sendNow = true
      if (this.tab === 0 && id)
        sendNow = false
      let title = id == null ? 'New Communication' : 'Edit Communication'
      if (disabled)
        title = 'Communication'
      this.$store.dispatch('crud', {
        dialog: true,
        title,
        id: id,
        disabled: disabled,
        data: {
          type_send: 2,
          sendNow: sendNow,
          viewVotes: true
        }
      })
    },
    openDialogDelete(item) {
      this.itemsDelete = [item]
      this.dialogDelete = true
    },
    closeDialogDelete() {
      this.itemsDelete = []
      this.dialogDelete = false
    },
    async deleteItems() {
      try {
        const res = await communication.deleted(this.itemsDelete[0].id)
        await this.$store.dispatch('alert', {
          show: true,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        this.closeDialogDelete()
        this.selected = []
        await this.initialLoad()
      } catch (e) {
        console.log(e)
      }
    },
    detailRecipient(id) {
      this.$store.dispatch('crudTwo', {
        dialog: true,
        title: 'Recipient list',
        id: id,
        data: {
          type: 1
        }
      })
    }
  }
}
</script>
