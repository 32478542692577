import {HTTP} from '../../../http-common'
import helper from '../index'

export default {
  /* getElections: async ({ id }) => {
    const response = await HTTP.get(
      "/elections/employees/" + id + "/vote-requests",
      {
        headers: helper.setHeaders(),
      }
    );
    return response.data.data;
  }, */
  getElections: async() => {
    const response = await HTTP.get('/elections/get', {
      headers: helper.setHeaders()
    })
    return response.data.data
  }
}
