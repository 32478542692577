<template>
  <div v-frag>
    <v-alert
      v-model='alert.show'
      :color='alert.color'
      class='rounded-xl'
      dark
      dense
      dismissible
      icon='mdi-check-circle-outline'
      prominent
      transition='scale-transition'
      width='100%'
    >
      <h3 class='mt-2'>{{ alert.title }}</h3>
      <p>{{ alert.message }}</p>
    </v-alert>
  </div>
</template>

<script>
export default {
  computed: {
    alert() {
      if (this.$store.getters.alert.show) {
        this.time()
      }
      return this.$store.getters.alert
    }
  },
  methods: {
    time() {
      const time = this.$store.getters.alert.timeout ? this.$store.getters.alert.timeout : 4000
      setTimeout(() => this.close(), time)
    },
    close() {
      this.$store.dispatch('alert', {
        show: false,
        color: 'grey',
        title: '',
        message: '',
        response: false
      })
    }
  }
}
</script>
