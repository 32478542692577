<template>
  <v-dialog
    v-model='crud.dialog'
    max-width='500px'
    persistent
  >
    <v-card>
      <v-card-title>
        <span class='headline'>{{ crud.title }}</span>
      </v-card-title>
      <v-card-text class='py-2 px-4'>
        <v-data-table
          :headers='headers'
          :items='desserts'
          :loading='loading'
          elevation='0'
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          class='font-weight-bold primary--text elevation-0'
          color='white'
          @click='closeDialog'
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import communication from '../../../services/communication'

export default {
  computed: {
    crud() {
      if (this.$store.getters.crudTwo.dialog) {
        this.initialLoad()
      }
      return this.$store.getters.crudTwo
    }
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: 'Name', align: 'start', value: 'name' },
        { text: 'Entity type', align: 'start', value: 'type' }
      ],
      desserts: []
    }
  },
  methods: {
    async initialLoad() {
      this.loading = true
      try {
        if (this.$store.getters.crudTwo.data.type === 1)
          this.desserts = await communication.recipient(this.$store.getters.crudTwo.id)
        if (this.$store.getters.crudTwo.data.type === 2)
          this.desserts = await communication.recipientTemplate(this.$store.getters.crudTwo.id)
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    closeDialog() {
      this.desserts = []
      this.$store.dispatch('crudTwo', {
        dialog: false,
        title: '',
        id: null,
        data: {}
      })
    }
  }
}
</script>
