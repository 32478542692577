<template>
  <div v-frag>
    <v-dialog
      v-model='crud.dialog'
      max-width='1000px'
      persistent
      scrollable
      transition='dialog-bottom-transition'
    >
      <v-card :loading='loadingData'>
        <v-card-title>
          <span class='headline'>{{ crud.title }}</span>
        </v-card-title>
        <v-card-text
          v-if='!loadingData'
          class='py-1 px-4'
        >
          <v-container>
            <v-form
              ref='form'
              v-model='valid'
              :disabled='crud.disabled'
              lazy-validation
            >
              <v-row>
                <v-col v-if='!crud.data.template' cols='6' class="mb-0 pb-0">
                  <p class='font-weight-bold mb-0'>Election*</p>
                  <v-select
                    v-model='inputs.election_id'
                    :items='elections'
                    :loading='loading'
                    item-value="id"
                    outlined
                    dense
                    class='mt-3'
                  >
                    <template #selection={item}>
                      {{ item.description }}
                    </template>
                    <template #item={item}>
                      {{ item.description }}
                    </template>
                  </v-select>
                </v-col>
                <!-- <div v-frag > -->
                <v-col v-if='!crud.data.template' cols='12' class="mt-0">
                  <p class='font-weight-bold mb-0'>Template*</p>
                  <v-row>
                    <v-col cols='4' md='3'>
                      <v-checkbox
                        v-model='inputs.templateSelect'
                        :rules='templateSelectRules'
                        :value='1'
                        class='mr-2 d-inline-block'
                        color='primary'
                        label='New communication'
                      ><template v-slot:label ><label class="py-1" @click.stop>New communication</label></template></v-checkbox>
                    </v-col>
                    <v-col cols='4' md='3'>
                      <v-checkbox
                        v-model='inputs.templateSelect'
                        :rules='templateSelectRules'
                        :value='2'
                        class='mr-2 d-inline-block'
                        color='primary'
                        label='Use template'
                        @click='getTemplates'
                      ><template v-slot:label ><label class="py-1" @click.stop>Use template</label></template></v-checkbox>
                    </v-col>

                    <div v-if='inputs.templateSelect === 2' v-frag>
                      <v-col cols='4' md='6'>
                        <v-autocomplete
                          v-model='inputs.template'
                          :items='itemsTemplates'
                          :rules='templateRules'
                          class='mt-3'
                          dense
                          outlined
                          placeholder='Template'
                          required
                          @change='selectTemplate'
                        ></v-autocomplete>
                      </v-col>
                    </div>
                  </v-row>
                </v-col>
                <!-- </div> -->

                <v-col v-if='crud.title !== "New template" && !crud.data.template' cols='12'
                >
                  <p class='font-weight-bold mb-0'>Type*</p>
                  <v-row>
                    <v-col cols='4' md='2'>
                      <v-checkbox
                        v-model='inputs.type'
                        :rules='typeRules'
                        :value='3'
                        class='mr-2 d-inline-block'
                        color='primary'
                        label='All'
                      ><template v-slot:label ><label class="py-1" @click.stop>All</label></template></v-checkbox>
                    </v-col>
                    <v-col cols='4' md='2'>
                      <v-checkbox
                        v-model='inputs.type'
                        :rules='typeRules'
                        :value='1'
                        class='mr-2 d-inline-block'
                        color='primary'
                        label='sms'
                      ><template v-slot:label ><label class="py-1" @click.stop>sms</label></template></v-checkbox>
                    </v-col>
                    <v-col cols='4' md='2'>
                      <v-checkbox
                        v-model='inputs.type'
                        :rules='typeRules'
                        :value='2'
                        class='mr-2 d-inline-block'
                        color='primary'
                        label='Email'
                      ><template v-slot:label ><label class="py-1" @click.stop>Email</label></template></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>

                <div v-if="!inputs.map && crud.title !== 'New template' && !crud.data.template" v-frag>
                  <v-col
                    v-if="user.rol === 'Business'"
                    cols='12'
                  >
                    <p class='font-weight-bold mb-0'>Send to Member*</p>
                    <v-row>
                      <v-col cols='4' md='3'>
                        <v-checkbox
                          v-model='inputs.sendToSelectMember'
                          :rules='sendToMembersRules'
                          :value='2'
                          class='mr-2 d-inline-block'
                          color='primary'
                          label='All Members'
                        ><template v-slot:label ><label class="py-1" @click.stop>All Members</label></template></v-checkbox>
                      </v-col>
                      <v-col cols='4' md='3'>
                        <v-checkbox
                          v-model='inputs.sendToSelectMember'
                          :rules='sendToMembersRules'
                          :value='1'
                          class='mr-2 d-inline-block'
                          color='primary'
                          label='Select Members'
                          @click='getMembers'
                        ><template v-slot:label ><label class="py-1" @click.stop>Select Members</label></template></v-checkbox>
                      </v-col>

                      <div v-if='inputs.sendToSelectMember === 1' v-frag>
                        <v-col cols='4' md='6'>
                          <v-autocomplete
                            v-model='inputs.sendToMember'
                            :items='itemsMembers'
                            :rules='memberRules'
                            class='mt-3'
                            dense
                            multiple
                            outlined
                            placeholder='Members'
                            required
                          >
                            <template #prepend-item>
                              <v-list-item>
                                <v-checkbox
                                  class='mb-4'
                                  color='primary'
                                  hide-details
                                  :disabled='!itemsMembers.length'
                                  v-model='selectedAllMembers'
                                >
                                  <template #append>
                                    <v-card-subtitle class='pa-0 ma-0'>Select All</v-card-subtitle>
                                  </template>
                                </v-checkbox>
                              </v-list-item>
                            </template>
                            <template #selection='{item, index}'>
                              <v-chip v-if='index < 2'>{{ item.text }}</v-chip>
                            </template>
                            <template #append>
                              <v-chip
                                v-if='inputs.sendToMember.length > 2'
                                class='ma-0'
                              >
                                {{ membersCount }}
                              </v-chip>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </div>
                    </v-row>
                  </v-col>

                  <v-col cols='12'>
                    <p class='font-weight-bold mb-0'>Send to Employee*</p>
                    <v-row>
                      <v-col
                        v-if="user.rol !== 'Manager'"
                        cols='4'
                        md='3'
                      >
                        <v-checkbox
                          v-model='inputs.sendTo'
                          :rules='sendToEmployeesRules'
                          :value='2'
                          class='mr-2 d-inline-block'
                          color='primary'
                          label='All Employees'
                        ><template v-slot:label ><label class="py-1" @click.stop>All Employees</label></template></v-checkbox>
                      </v-col>

                      <v-col cols='4' md='3'>
                        <v-checkbox
                          v-model='inputs.sendTo'
                          :rules='sendToEmployeesRules'
                          :value='3'
                          class='mr-2 d-inline-block'
                          color='primary'
                          label='Hierarchy Level'
                        ><template v-slot:label ><label class="py-1" @click.stop>Hierarchy Level</label></template></v-checkbox>
                      </v-col>

                      <v-col cols='4' md='3'>
                        <v-checkbox
                          v-model='inputs.sendTo'
                          :rules='sendToEmployeesRules'
                          :value='1'
                          class='mr-2 d-inline-block'
                          color='primary'
                          label='Select Employees'
                          @click='getEmployees'
                        ><template v-slot:label ><label class="py-1" @click.stop>Select Employees</label></template></v-checkbox>
                      </v-col>
                      <div v-if='inputs.sendTo === 1' v-frag>
                        <v-col cols='4' md='3'>
                          <v-autocomplete
                            v-model='inputs.sendToEmployee'
                            :items='itemsEmployees'
                            :rules='employeeRules'
                            class='mt-3'
                            dense
                            multiple
                            outlined
                            placeholder='Employee'
                            required
                          >
                            <template #prepend-item>
                              <v-list-item>
                                <v-checkbox
                                  v-model='selectedAllEmployees'
                                  :disabled='!itemsEmployees.length'
                                  class='mb-4'
                                  color='primary'
                                  hide-details
                                >
                                  <template #append>
                                    <v-card-subtitle class='pa-0 ma-0'>Select All</v-card-subtitle>
                                  </template>
                                </v-checkbox>
                              </v-list-item>
                            </template>
                            <template #selection='{item, index}'>
                              <v-chip v-if='index < 2'>{{ item.text }}</v-chip>
                            </template>
                            <template #append>
                              <v-chip v-if='inputs.sendToEmployee.length > 2'>{{ employeeCount }}</v-chip>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </div>
                    </v-row>
                  </v-col>

                  <div v-if='inputs.sendTo === 2 || inputs.sendTo === 3' v-frag>
                    <v-col cols='12'>
                      <p class='font-weight-bold mb-0'>Send to</p>
                      <v-row>
                        <v-col cols='3' lg='2' md='2' sm='4' xl='3' xs='4'>
                          <v-select
                            v-model='inputs.voted'
                            :items='itemsVoted'
                            class='mt-3'
                            dense
                            label='Voted'
                            outlined
                            placeholder='Employee'
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols='3' md='2'>
                          <v-select
                            v-model='inputs.register'
                            :items='itemsRegister'
                            class='mt-3'
                            dense
                            label='Registered'
                            outlined
                            placeholder='Employee'
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols='3' lg='2' md='2' sm='4' xl='3' xs='4'>
                          <v-select
                            v-model='inputs.scheduleVote'
                            :items='itemsScheduled'
                            class='mt-3'
                            dense
                            label='Scheduled to vote'
                            outlined
                            placeholder='Scheduled to vote'
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols='3' lg='2' md='2' sm='4' xl='3' xs='4'>
                          <v-select
                            v-model='inputs.county'
                            :items='itemsCounty'
                            class='mt-3'
                            dense
                            label='County'
                            outlined
                            placeholder='County'
                            required
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                  <div v-if='inputs.sendTo !== 1' v-frag>
                    <v-col class='py-0' cols='6' lg='6' md='6' sm='5' xl='6' xs='5'>
                      <v-checkbox
                        v-model='inputs.noVoter'
                        :rules='sendToEmployeesRules'
                        :value='1'
                        class='mt-0 d-inline-block'
                        color='primary'
                        label='Exclude Employees with No Voter Guide'

                      ><template v-slot:label ><label class="py-1" @click.stop>Exclude Employees with No Voter Guide</label></template></v-checkbox>
                    </v-col>
                    <v-col class='py-0 d-flex' cols='6' lg='6' md='6' sm='5' xl='6' xs='5'>
                      <v-checkbox
                        v-model='inputs.ineligible'
                        :rules='sendToEmployeesRules'
                        :value='1'
                        class='mt-0 d-inline-block'
                        color='primary'
                        label='Exclude ineligible'
                        @click.stop
                      ><template v-slot:label ><label class="py-1" @click.stop>Exclude ineligible</label></template></v-checkbox>

                    </v-col>
                  </div>
                  <div v-if='inputs.sendTo === 3' v-frag>
                    <v-col cols='12'>
                      <p class='font-weight-bold mb-0'>Hierarchy*</p>
                      <v-row no-gutters>
                        <v-col
                          v-for='(item, index) in checkCheckbox'
                          :key='index'
                          cols='4'
                          md='3'
                        >
                          <v-checkbox
                            v-model='inputs.role'
                            class="d-inline-block"
                            :label='item.text'
                            :rules='rolRules'
                            :value='item.value'
                            color='primary'
                          ><template v-slot:label ><label class="py-1" @click.stop>{{ item.text }}</label></template></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>

                  <v-col cols='12'>
                    <v-checkbox
                      v-model='inputs.linkVoted'
                      :value='inputs.value'
                      color='primary'
                      label=''
                      class="d-inline-block"
                    ><template v-slot:label><label class="py-1" @click.stop>Include Voters Guide</label></template></v-checkbox>
                  </v-col>
                </div>

                <div v-if='!crud.data.template' v-frag></div>
                <div v-else v-frag>
                  <v-col cols='12'>
                    <p class='font-weight-bold'>Template name*</p>
                    <v-text-field
                      v-model='templateName'
                      :rules='templateNameRules'
                      dense
                      outlined
                      placeholder=''
                      required
                      type='text'
                    ></v-text-field>
                  </v-col>
                </div>

                <v-col cols='12'>
                  <p class='font-weight-bold'>Communication Topic*</p>
                  <v-text-field
                    v-model='inputs.topic'
                    :rules='topicRules'
                    dense
                    outlined
                    placeholder='new Campaign'
                    required
                    type='text'
                  ></v-text-field>
                </v-col>

                <div v-if='inputs.type !== 1' v-frag>
                  <v-col cols='12'>
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols='12'>
                    <p class='font-weight-bold'>Email Subject*</p>
                    <v-text-field
                      v-model='inputs.email'
                      :rules='emailRules'
                      dense
                      outlined
                      placeholder='Remaider Email Example'
                      required
                      type='text'
                    ></v-text-field>
                  </v-col>

                  <v-col cols='12'>
                    <p class='font-weight-bold'>Email Message*</p>
                    <wysiwyg
                      v-model='inputs.emailMessage'
                      :rules='emailMessageRules'
                    />
                  </v-col>
                </div>

                <div v-if='inputs.type !== 2' v-frag>
                  <v-col cols='12'>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols='12'>
                    <p class='font-weight-bold'>Sms Message*</p>
                    <v-textarea
                      v-model='inputs.smsMessage'
                      :rules='smsMessageRules'
                      dense
                      outlined
                      required
                      type='text'
                    ></v-textarea>
                  </v-col>
                </div>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class='d-flex direction-row justify-end'>
          <v-btn
            :disabled='loading || loadingData'
            class='px-14 mx-4 font-weight-bold primary--text elevation-0'
            color='white'
            large
            @click='closeDialog'
          >
            Cancel
          </v-btn>
          <div v-if='!crud.disabled'>
            <div v-if='crud.data.campaign' v-frag>
              <v-btn
                :disabled='loading || loadingData'
                :loading='loading'
                class='px-14 mx-4 font-weight-bold white--text'
                color='primary'
                large
                @click='saveCommunicationCampaign'
              >
                Save
              </v-btn>
            </div>
            <div v-else-if='crud.data.template' v-frag>
              <v-btn
                :disabled='loading || loadingData'
                :loading='loadingData'
                class='px-14 mx-4 font-weight-bold white--text'
                color='primary'
                large
                @click='saveTemplate'
              >
                Save Template
              </v-btn>
            </div>
            <div v-else v-frag>
              <v-btn
                :disabled='loading || loadingData'
                :loading='loading || loadingData'
                class='px-14 mx-4 font-weight-bold white--text'
                color='primary'
                large
                outlined
                @click='openSchedule'
              >
                Schedule send
                <v-icon right> mdi-send</v-icon>
              </v-btn>
              <div v-if='!crud.data.sendNow' v-frag></div>
              <div v-else v-frag>
                <v-btn
                  :disabled='loading || loadingData'
                  :loading='loading || loadingData'
                  class='px-14 mx-4 font-weight-bold white--text'
                  color='primary'
                  large
                  @click='save'
                >
                  Send now
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model='dialogSchedule'
      persistent
      width='500'
    >
      <v-card>
        <v-card-text class='py-2 px-4'>
          <v-container>
            <v-form
              ref='form'
              v-model='valid'
              lazy-validation
            >
              <v-row>
                <!-- <div v-frag > -->
                <v-col cols='12'>
                  <v-menu
                    ref='menu3'
                    v-model='modalDate'
                    :close-on-content-click='false'
                    max-width='290px'
                    min-width='auto'
                    offset-y
                    transition='scale-transition'
                  >
                    <template #activator='{ on, attrs }'>
                      <p class='font-weight-bold'>Date*</p>
                      <v-text-field
                        :value='computedDateFormattedMomentjs'
                        autocomplete='off'
                        dense
                        outlined
                        required
                        v-bind='attrs'
                        v-on='on'
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model='inputs.date'
                      no-title
                      @input='modalDate = false'
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols='12'>
                  <v-time-picker
                    v-model='timeView'
                    ampm-in-title
                    close-on-complete
                    format='ampm'
                    landscape
                    @change='updatedTime'
                  ></v-time-picker>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class='d-flex direction-row justify-end'>
          <v-btn
            class='px-14 mx-4 font-weight-bold primary--text elevation-0'
            color='white'
            large
            @click='closeDialogSchedule'
          >
            Cancel
          </v-btn>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='saveSchedule'
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import user from '../../../services/user'
import communication from '../../../services/communication'
import moment from 'moment'
import electionsService from "@/services/elections";

export default {
  props: {
    tableSelected: {
      type: Array,
      default: () => []
    },
    treeviewSelected: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedAllEmployees: false,
      selectedAllMembers: false,
      inputs: {
        templateSelect: 1,
        template: '',
        type: 3,
        sendToSelectMember: 2,
        sendToMember: '',
        sendTo: 2,
        sendToEmployee: '',
        role: [],
        topic: '',
        email: '',
        emailMessage: '',
        noVoter: null,
        smsMessage: '',
        date: new Date().toISOString().substr(0, 10),
        schedule: false,
        time: '',
        scheduleVote: null,
        voted: null,
        register: null,
        county: null,
        map: false,
        ineligible: null,
        linkVoted: false,
        election_id: null,
      },
      communicationType: 'all',
      templateName: '',
      loading: false,
      loadingData: false,
      valid: false,
      topicRules: [(v) => !!v || 'Topic is required'],
      memberRules: [(v) => !!v || 'Members is required'],
      employeeRules: [(v) => !!v || 'Employee is required'],
      templateRules: [(v) => !!v || 'Template is required'],
      emailRules: [(v) => !!v || 'Email Subject is required'],
      emailMessageRules: [(v) => !!v || 'Email message is required'],
      smsMessageRules: [(v) => !!v || 'Sms message is required'],
      templateNameRules: [(v) => !!v || 'Template name message is required'],
      itemsMembers: [],
      itemsEmployees: [],
      itemsCounty: [],
      itemsCheckbox: [],
      itemsTemplates: [],
      dialogSchedule: false,
      menu3: false,
      modalDate: false,
      timeView: '',
      campaignCurrent: '',
      viewVotes: false,
      itemsScheduled: [
        { value: null, text: 'All' },
        { value: 2, text: 'Yes' },
        { value: 1, text: 'No' }
      ],
      itemsRegister: [
        { value: null, text: 'All' },
        { value: 2, text: 'Yes' },
        { value: 1, text: 'No' }
      ],
      itemsVoted: [
        { value: null, text: 'All' },
        { value: 2, text: 'Yes' },
        { value: 1, text: 'No' }
      ],
      selectedEmployeesId: [],
      elections: null,
    }
  },
  computed: {
    ...mapState({
      currentElection: state => state.electionStore.selectedElection
    }),
    checkCheckbox() {
      if (this.$store.getters.user.rol === 'Manager') {
        return this.itemsCheckbox.filter(el => el.value !== 1 && el.value !== 2)
      }
      return this.itemsCheckbox
    },
    user() {
      return this.$store.getters.user
    },
    crud() {
      if (this.$store.getters.crud.dialog) {
        this.initialLoad()
      }
      return this.$store.getters.crud
    },
    rolRules() {
      return [
        this.inputs.role.length > 0 || 'At least one item should be selected'
      ]
    },
    sendToMembersRules() {
      return [
        this.inputs.sendToSelectMember != null || 'At least one item should be selected'
      ]
    },
    sendToEmployeesRules() {
      return [
        this.inputs.sendTo != null || 'At least one item should be selected'
      ]
    },
    typeRules() {
      return [
        this.inputs.type != null || 'At least one item should be selected'
      ]
    },
    templateSelectRules() {
      return [
        this.inputs.templateSelect != null ||
        'At least one item should be selected'
      ]
    },
    computedDateFormattedMomentjs() {
      return this.inputs.date
        ? moment(this.inputs.date)
          .format('MM/DD/YY')
        : ''
    },
    employeeCount() {
      return `+ ${ this.inputs.sendToEmployee.length - 2 }`
    },
    membersCount() {
      return `+ ${ this.inputs.sendToMember.length - 2 }`
    }
  },
  created() {
    this.getElections()
  },
  watch: {
    'inputs.sendToEmployee': {
      handler() {
        this.inputs.sendToEmployee.length === 0
          ? this.selectedAllEmployees = false
          : null
      },
      deep: true
    },
    'inputs.sendToMember': {
      handler() {
        this.inputs.sendToMember.length === 0
          ? this.selectedAllMembers = false
          : null
      },
      deep: true
    },
    selectedAllEmployees() {
      this.selectedAllEmployees
        ? this.inputs.sendToEmployee = this.itemsEmployees.map(el => el.value)
        : this.inputs.sendToEmployee = []
    },
    selectedAllMembers() {
      this.selectedAllMembers
        ? this.inputs.sendToMember = this.itemsMembers.map(el => el.value)
        : this.inputs.sendToMember = []
    },
    async 'inputs.type'(newVal) {
      if (newVal === 1)
        this.communicationType = 'sms'
      else if (newVal === 2)
        this.communicationType = 'email'
      else
        this.communicationType = 'all'
      if (this.inputs.sendToSelectMember === 1)
        await this.getMembers()
      if (this.inputs.sendTo === 1)
        await this.getEmployees()
    }
  },
  methods: {
    ...mapActions('communicationStore', ['getCounties']),
    async initialLoad() {
      this.loadingData = true
      if (this.tableSelected.length)
        this.tableSelected.forEach(el => this.selectedEmployeesId.push(el.id))
      if (this.treeviewSelected.length)
        this.treeviewSelected.forEach(id => this.selectedEmployeesId.push(id))
      if (this.$store.getters.crud.data?.employees?.length)
        this.selectedEmployeesId = this.$store.getters.crud.data.employees
      this.itemsCounty = await this.getCounties()
      this.itemsCounty.unshift({ value: null, text: 'All' })
      this.inputs.sendToEmployee = this.selectedEmployeesId
      const aux = this.inputs
      aux.templateSelect = 1
      aux.template = ''
      aux.type = 3
      aux.sendToSelectMember = 2
      if (this.selectedEmployeesId.length) {
        aux.sendTo = 1
        await this.getEmployees()
      } else {
        aux.sendTo = 2
      }
      aux.role = []
      aux.schedule = false
      aux.map = false
      aux.ineligible = 0
      this.timeView = ''
      this.inputs = aux
      this.inputs.time = ''
      this.inputs.linkVoted = false
      this.inputs.date = new Date().toISOString().substr(0, 10)
      try {
        await Promise.allSettled([
          this.getEmployeeRoles(),
          this.getElections()
        ])
        if (this.$store.getters.crud.id) {
          await Promise.allSettled([
            this.getMembers(),
            this.getEmployees(),
            this.getData(),
            this.getElections()
          ])
        }
      } catch (e) {
        console.log(e)
      }
      this.loadingData = false
    },
    async getEmployees() {
      let aux = {
        send_to_select_member: this.inputs.sendToSelectMember,
        member: this.inputs.sendToMember,
        type: this.communicationType
      }
      this.itemsEmployees = await communication.getEmployees(aux)
      if (this.itemsEmployees.length === 0) {
        let aux = this.inputs
        aux.sendToEmployee = ''
        this.inputs = aux
      }
    },
    async getMembers() {
      try {
        this.itemsEmployees = []
        this.itemsMembers = await user.getActiveMembers(this.communicationType)
      } catch (e) {
        console.log(e)
      }
    },
    async getTemplates() {
      try {
        this.itemsTemplates = await communication.getTemplates()
      } catch (e) {
        console.log(e)
      }
    },
    async getEmployeeRoles() {
      try {
        // aux.unshift({ text: "All Employees", value: 0 });
        this.itemsCheckbox = await communication.getEmployeeRoles()
      } catch (e) {
        console.log(e)
      }
    },
    async selectTemplate() {
      try {
        await Promise.allSettled([
          this.getMembers(),
          this.getEmployees()
        ])
        const res = await communication.show(this.inputs.template)
        let aux = this.inputs
        aux.type = res.type_id
        aux.sendTo = res.send_to
        aux.role = res.role
        aux.topic = res.topic
        aux.email = res.subject
        aux.emailMessage = res.text_mail
        aux.smsMessage = res.text_sms
        aux.sendToEmployee = res.entity_send_id
        aux.sendToSelectMember = res.send_to_member
        aux.sendToMember = res.member_send_id
        this.inputs = aux
      } catch (e) {
        console.log(e)
      }
    },
    async getData() {
      try {
        let aux = this.inputs
        let res
        if (this.crud.data.template) {
          res = await communication.show(this.$store.getters.crud.id)
          this.templateName = res.template_name
          aux.schedule = false
          aux.date = ''
        } else {
          res = await communication.showCommunication(this.$store.getters.crud.id)
          aux.schedule = res.schedule
          aux.date = res.date
          aux.time = res.time
          this.timeView = res.timeView
          // if (res.campaign) {
          aux.voted = res.voted
          aux.register = res.register
          aux.scheduleVote = res.schedule_vote
          // }
          aux.map = res.map
        }
        aux.county = this.itemsCounty.filter(el => el.value == res.county_id)[0]
        aux.type = res.type_id
        aux.sendTo = res.send_to
        aux.role = res.role
        aux.topic = res.topic
        aux.email = res.subject
        aux.emailMessage = res.text_mail
        aux.smsMessage = res.text_sms
        aux.sendToEmployee = res.entity_send_id
        aux.sendToSelectMember = res.send_to_member
        aux.sendToMember = res.member_send_id
        aux.linkVoted = res.link_voted
        aux.noVoter = res.no_voter
        aux.ineligible = res.ineligible
        aux.link_voted = res.campaign_id
        aux.election_id = res.campaign_id
        this.inputs = aux
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      if (this.crud.id) {
        if (this.inputs.map) {
          await this.updateReportingMap()
        } else {
          await this.updated()
        }
      } else {
        await this.store()
      }
    },
    async store() {
      this.loading = true
      if (this.$refs.form.validate()) {
        try {
          let aux = {
            type_id: this.inputs.type,
            type_send: this.crud.data.type_send,
            // member
            send_to_member: this.inputs.sendToSelectMember,
            member_send_id: this.inputs.sendToMember,
            // fin member
            // employee
            send_to: this.inputs.sendTo,
            entity_send_id: this.inputs.sendToEmployee,
            // fin employee
            role_id: this.inputs.role,
            topic: this.inputs.topic,
            subject: this.inputs.email,
            text_mail: this.inputs.emailMessage,
            text_sms: this.inputs.smsMessage,
            noVoter: this.inputs.noVoter,

            schedule: this.inputs.schedule,
            date: this.inputs.date,
            time: this.inputs.time,
            template: this.inputs.templateSelect,
            template_id: this.inputs.template,

            voted: this.inputs.voted,
            register: this.inputs.register,
            scheduleVote: this.inputs.scheduleVote,
            county_id: this.inputs.county,

            ineligible: this.inputs.ineligible,
            link_voted: this.inputs.linkVoted,
            campaign_id: this.inputs.election_id
          }
          const res = await communication.store(aux)
          await this.$store.dispatch('alert', {
            show: true,
            color: 'green',
            title: 'Success message!',
            message: res.data.message,
            response: true
          })
          await this.closeDialog()
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    async updated() {
      this.loading = true
      if (this.$refs.form.validate()) {
        try {
          let aux = {
            type_id: this.inputs.type,
            type_send: this.crud.data.type_send,
            // member
            send_to_member: this.inputs.sendToSelectMember,
            member_send_id: this.inputs.sendToMember,
            // fin member
            // employee
            send_to: this.inputs.sendTo,
            entity_send_id: this.inputs.sendToEmployee,
            // fin employee
            role_id: this.inputs.role,
            topic: this.inputs.topic,
            subject: this.inputs.email,
            text_mail: this.inputs.emailMessage,
            text_sms: this.inputs.smsMessage,
            noVoter: this.inputs.noVoter,
            schedule: this.inputs.schedule,
            date: this.inputs.date,
            time: this.inputs.time,
            template: this.inputs.templateSelect,
            template_id: this.inputs.template,
            county_id: this.inputs.county,

            voted: this.inputs.voted,
            register: this.inputs.register,
            ineligible: this.inputs.ineligible,
            link_voted: this.inputs.linkVoted,
            campaign_id: this.inputs.election_id
          }
          const res = await communication.updated(this.crud.id, aux)
          await this.$store.dispatch('alert', {
            show: true,
            color: 'green',
            title: 'Success message!',
            message: res.message,
            response: true
          })
          await this.closeDialog()
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    async updateReportingMap() {
      this.loading = true
      if (this.$refs.form.validate()) {
        try {
          let aux = {
            type_id: this.inputs.type,
            type_send: this.crud.data.type_send,
            noVoter: this.inputs.noVoter,
            topic: this.inputs.topic,
            subject: this.inputs.email,
            text_mail: this.inputs.emailMessage,
            text_sms: this.inputs.smsMessage,
            county_id: this.inputs.county,

            schedule: this.inputs.schedule,
            date: this.inputs.date,
            time: this.inputs.time,
            template: this.inputs.templateSelect,
            template_id: this.inputs.template,
            map: true,
            campaign_id: this.inputs.election_id
          }
          const res = await communication.updateReportingMap(this.crud.id, aux)
          await this.closeDialog()
          await this.$store.dispatch('alert', {
            show: true,
            color: 'green',
            title: 'Success message!',
            message: res.message,
            response: true
          })
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    openSchedule() {
      this.timeView = this.timeView.split(' ').join('')
      this.dialogSchedule = true
    },
    async saveSchedule() {
      let aux = this.inputs
      aux.time = this.timeView
      this.inputs = aux
      aux.schedule = true
      this.inputs = aux
      this.dialogSchedule = false
      await this.save()
    },
    closeDialogSchedule() {
      this.dialogSchedule = false
      let aux = this.inputs
      aux.schedule = false
      aux.date = ''
      aux.time = ''
      this.inputs = aux
    },
    setTime() {
      if (this.inputs.time) {
        const timepicker = this.$refs.timepicker
        this.timeView = timepicker.convert12to24(timepicker.inputHour) +
          ':' +
          timepicker.inputMinute +
          ' ' +
          timepicker.period.toUpperCase()
      }
    },

    // template
    async saveTemplate() {
      this.loading = true
      if (this.$refs.form.validate()) {
        try {
          let aux = {
            type_id: this.inputs.type,
            type_send: this.crud.data.type_send,
            // member
            send_to_member: this.inputs.sendToSelectMember,
            member_send_id: this.inputs.sendToMember,
            // fin member
            // employee
            send_to: this.inputs.sendTo,
            entity_send_id: this.inputs.sendToEmployee,
            // fin employee
            role_id: this.inputs.role,
            topic: this.inputs.topic,
            subject: this.inputs.email,
            text_mail: this.inputs.emailMessage,
            text_sms: this.inputs.smsMessage,
            template_name: this.templateName
          }
          let res
          if (this.crud.id == null) {
            res = await communication.storeTemplate(aux)
          } else {
            res = await communication.updateTemplate(this.crud.id, aux)
          }
          await this.closeDialog()
          await this.$store.dispatch('alert', {
            show: true,
            color: 'green',
            title: 'Success message!',
            message: res.message,
            response: true
          })
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    async closeDialog() {
      await this.$refs.form.reset()
      this.selectedEmployeesId = []
      this.$emit('clearSelected')
      this.$emit('update-table')
      this.inputs.emailMessage = ''
      await this.$store.dispatch('crud', {
        dialog: false,
        title: '',
        id: null,
        data: {}
      })
    },
    async saveCommunicationCampaign() {
      this.loading = true
      if (this.$refs.form.validate()) {
        try {
          let aux = {
            type_id: this.inputs.type,
            type_send: this.crud.data.type_send,
            // member
            send_to_member: this.inputs.sendToSelectMember,
            member_send_id: this.inputs.sendToMember,
            // fin member
            // employee
            send_to: this.inputs.sendTo,
            entity_send_id: this.inputs.sendToEmployee,
            // fin employee
            role_id: this.inputs.role,
            topic: this.inputs.topic,
            subject: this.inputs.email,
            text_mail: this.inputs.emailMessage,
            text_sms: this.inputs.smsMessage,

            schedule: true,
            date: this.crud.data.date,
            time: this.crud.data.time,
            template: this.inputs.templateSelect,
            template_id: this.inputs.template,

            campaign: true,

            voted: this.inputs.voted,
            register: this.inputs.register,
            scheduleVote: this.inputs.scheduleVote,
            ineligible: this.inputs.ineligible,
            link_voted: this.inputs.linkVoted,
            campaign_id: this.inputs.election_id
          }
          if (this.crud.id == null) {
            await communication.store(aux)
          } else {
            await communication.updated(this.crud.id, aux)
          }
          await this.$store.dispatch('eventBus', true)
          await this.closeDialog()
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    async getElections() {
      const data = await electionsService.getElections()
      const nowDate = Date.parse(new Date().toDateString())
      this.elections = data.reverse().filter(value => Date.parse(value.end) >= nowDate)
      let recentElection = this.elections[0];
      this.elections.forEach((value, index) => {
        if (index !== 0) {
          let valueDate = Date.parse(value.start)
          let recentDate = Date.parse(recentElection.start)
          if (valueDate > recentDate)
            recentElection = value
        }
      })
      if (!this.inputs.election_id)
      this.inputs.election_id = recentElection.id
    },
    updatedTime() {
      let aux = this.inputs
      aux.time = this.timeView
      this.inputs = aux
    }
  }
}
</script>
